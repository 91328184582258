import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import featureImage from "../../static/images/hero-round.png"
import macOSAppStoreBadge from "../../static/images/Mac_App_Store_Badge.svg"

const IndexPage = ({ location }) => (
  <Layout>
    <SEO
      title="McPiper - macOS app to monitor GitLab CI/CD pipeline status"
      currentUrl={location.pathname}
    />

    <div className={"page-header home"}>
      <h1>Monitor GitLab CI/CD from macOS status bar</h1>
      <p>
        McPiper notifies you when CI/CD pipelines fail.
      </p>
      <p>
        Whether it's your merge request or deployment to production.
      </p>
      <img alt={"Dashboard"} src={featureImage} />
      <h2>Features</h2>
      <p>Notifications support</p>
      <p>Private repository support</p>
      <p>Monitor multiple repositories</p>
      <p>Support for private GitLab instances</p>
      <p>Works with VPNs</p>
      <p>Only 2.4MB in size</p>
      <p>Dark mode support</p>
      <div>
          <a
            href={"https://apps.apple.com/us/app/mcpiper/id1517471189?ls=1"}
            title="Download McPiper on Mac App Store"
          >
            <img alt="Mac App Store badge" src={macOSAppStoreBadge} />
          </a>
        </div>
    </div>
  </Layout>
)

export default IndexPage
